@charset "UTF-8";

/*========================================================================
* News 리스트
========================================================================*/
.cardList { padding-top: 20px; }
.cardList ul { display: flex; flex-wrap: wrap; gap: 32px; }
.cardList ul li { display: flex; flex-direction: column; padding: 20px; width: calc((100% / 3) - 21.4px); border: 1px solid #ABABAB; position: relative; overflow: hidden; }
.cardList ul li .photo { display: flex; justify-content: center; align-items: center; height: 224px; border: 1px solid rgba(0,0,0,0.05); border-radius: 6px; position: relative; overflow: hidden; }
.cardList ul li .photo img { margin: auto; max-width: 80%; position: absolute; top: -100%; right: -100%; bottom: -100%; left: -100%; transition: 0.3s ease-in-out; }
.cardList ul li:hover .photo img { transform: scale(1.05); }
.cardList ul li .cont { flex: 1; display: flex; flex-direction: column; padding: 20px 20px 0; }
.cardList ul li .tit { display: -webkit-box; max-height: 60px; font-size: 20px; font-weight: 500; color: #4a4a4a; line-height: 1.4; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; }
.cardList ul li .info { display: flex; justify-content: space-between; margin-top: 16px; }
.cardList ul li .info .date { font-size: 15px; color: #ABABAB; }
.cardList ul li a { width: 100%; height: 100%; font-size: 0; position: absolute; top: 0; left: 0; z-index: 1; }

@media screen and (max-width: 1024px) {
  .cardList ul { gap: 16px; }
  .cardList ul li { padding: 12px; width: calc((100% / 3) - 10.7px); }
  .cardList ul li .photo { height: 180px; }
  .cardList ul li .cont { padding: 20px 10px 0; }
  .cardList ul li .tit { font-size: 20px; }
  .cardList ul li .info .date { font-size: 14px; }
}

@media screen and (max-width: 767px) {
  .cardList ul li { width: calc((100% / 2) - 8px); }
  .cardList ul li .tit { font-size: 18px; }
}

@media screen and (max-width: 550px) {
  .cardList ul li { padding: 0; width: 100%; border: 0; }
  .cardList ul li + li { margin-top: 16px; }
  .cardList ul li .photo { height: 224px; }
  .cardList ul li .photo img { max-width: 90%; }
  .cardList ul li .cont { padding: 10px 10px 0; }
  .cardList ul li .info { margin-top: 8px;}
}

@media screen and (max-width: 360px) {
  .cardList ul li .photo { height: 180px; }
  .cardList ul li .tit { font-size: 17px; }
  .cardList ul li .info .date { font-size: 13px; }
}


/*========================================================================
* News 상세 페이지
========================================================================*/
.dtArea { color: #000; }
.dtArea .dtAreaTit { padding: 1rem 0.5rem; border-bottom: 2px solid #FCC800; }
.dtArea .dtAreaTit h1 { font-size: 26px; color: #FCC800; }
.dtArea .dtAreaCont { padding: 3rem; border-bottom: 2px solid rgba(0, 0, 0, 0.2); }
.dtArea .dtAreaCont .dtItemHdr { margin-bottom: 40px; }
.dtArea .dtAreaCont .dtItemHdr h2 { margin-bottom: 12px; font-size: 32px; font-weight: 600; color: #14539a; }
.dtArea .dtAreaCont .dtItemHdr p { font-size: 16px; font-weight: 400; color: #ABABAB; }
.dtArea .dtAreaCont .dtItemBd .itemCont { line-height: 1.8; font-size: 16px; word-break: keep-all; }
.dtArea .dtAreaCont .dtItemBd .itemCont .text-tiny { font-size: 12px; }
.dtArea .dtAreaCont .dtItemBd .itemCont .text-small { font-size: 14px; }
.dtArea .dtAreaCont .dtItemBd .itemCont .text-big { font-size: 18px; }
.dtArea .dtAreaCont .dtItemBd .itemCont .text-huge { font-size: 20px; }
.dtArea .dtAreaCont .dtItemBd .itemCont figure.table { margin: 0 auto; }
.dtArea .dtAreaCont .dtItemFt { margin-top: 40px; }
.dtArea .dtAreaCont .dtItemFt button { display: inline-flex; justify-content: center; align-items: center; padding: 0 1rem; width: 150px; height: 40px; font-size: 16px; color: #14539a; background-color: #fff; border: 2px solid #14539a; border-radius: 20px; cursor: pointer; transition: 0.2s ease-in-out; }
.dtArea .dtAreaCont .dtItemFt button:hover { box-shadow: 0 10px 10px rgba(0,0,0,0.1); }

@media screen and (max-width: 1024px) {
  .dtArea .dtAreaCont { padding: 2rem; }
  .dtArea .dtAreaCont .dtItemHdr h2 { line-height: 1.2; }
}

@media screen and (max-width: 767px) {
  .dtArea .dtAreaCont { padding: 1.5rem; }
  .dtArea .dtAreaCont .dtItemHdr h2 { font-size: 28px; }
  .dtArea .dtAreaCont .dtItemBd .itemCont { font-size: 16px; }
}

@media screen and (max-width: 550px) {
  .dtArea .dtAreaCont .dtItemHdr h2 { font-size: 26px; }
  .dtArea .dtAreaCont .dtItemBd .itemCont { font-size: 15px; }
  .dtArea .dtAreaCont .dtItemBd img { margin-bottom: 25px; } 
}

@media screen and (max-width: 360px) {
  .dtArea .dtAreaCont { padding: 1.5rem 0; }
  .dtArea .dtAreaCont .dtItemHdr { margin-bottom: 30px; }
  .dtArea .dtAreaCont .dtItemHdr h2 { font-size: 24px; }
  .dtArea .dtAreaCont .dtItemBd .itemCont { font-size: 14px; }
}