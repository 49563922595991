@charset "UTF-8";

:root {
  --modalHeight: 50px;
}


/*========================================================================
* Modal Styles
========================================================================*/
.modal { z-index: 1000; visibility: hidden; opacity: 0; position: fixed; justify-content: center; align-items: center; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(0,0,0,0.4); transition: 0.3s all ease-in-out; }
.modal.show { visibility: unset; opacity: 1; backdrop-filter: blur(3px); }
.modal .modalBox { position: absolute; top: 50%; left: 50%; opacity: 0; transform: translate(-50%, -45%); width: 100%; max-width: 450px; border-radius: 6px; overflow: hidden; background-color: #fff; box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15); transition: 0.3s all ease-in-out; }
.modal.show .modalBox { opacity: 1; transform: translate(-50%, -50%); }

.modal .modalBox .modalHeader { display: flex; justify-content: center; align-items: center; height: var(--modalHeight); padding: 1rem 1.5rem; color: #fff; background-color: var(--sfrtBlue_400); border-radius: 6px 6px 0 0; box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); }
.modal .modalBox .modalHeader h2 { font-size: 1.2rem; }
.modal .modalBox .modalHeader button { position: absolute; top: calc(var(--modalHeight) / 2); right: calc(var(--modalHeight) / 3); font-size: 1.4rem; transform: translateY(-50%); }

.modal .modalBox .modalBody { margin: 1.5rem 0; padding: 0 2rem; max-height: 600px; line-height: 1.4; overflow-y: auto; }
.modal .modalBox .modalBody .modalBodyTit { margin-bottom: 30px; font-size: 1rem; font-weight: 600; }
.modal .modalBox .modalBody .modalBodyTit.center { text-align: center; }

.modal .btnArea .btn { width: 150px; }

.modal .modalBox .modalFoot { display: flex; justify-content: center; gap: 12px; margin: 30px 0; padding: 0 2rem; }
.modal .modalBox .modalFoot .btn { width: 150px; }
.modal .modalBox .modalFoot.left { justify-content: flex-start; }
.modal .modalBox .modalFoot.right { justify-content: flex-end; }
.modal .modalBox .modalFoot.full .btn { width: 100%; }

/* 임시 */
.modal.sample { z-index: 0; visibility: unset; opacity: 1; position: relative; background-color: #fff; transition: none; }
.modal.sample .modalBox { position: relative; top: 0; left: 0; opacity: 1; transform: unset; transition: none; }
.modal.app .field :is(.textarea, .select, .input) { width: 100%; }


/*========================================================================
* Toast Message Styles
========================================================================*/
.toast:not(.sample) { z-index: 1100; visibility: hidden; opacity: 0; position: fixed; right: 3%; bottom: 3%; transform: translate(3%, -3%); }
.toast:not(.sample).show { visibility: unset; z-index: 1000; animation: toastAm 5s ease forwards; }
@keyframes toastAm {
  14%, 86% { opacity: 1; right: 3%; bottom: 5%; transform: translate(3%, -5%); }
  100% { opacity: 0; right: 3%; bottom: 3%; transform: translate(3%, -3%); }
}
.toast .toastBox.success { --toastColor: var(--successColor); }
.toast .toastBox.warning { --toastColor: var(--warningColor); }
.toast .toastBox.error { --toastColor: var(--errorColor); }
.toast .toastBox.notice { --toastColor: var(--noticeColor); }
.toast .toastBox.info { --toastColor: var(--toastInfoColor); }

.toast .toastBox { display: inline-flex; padding: 1.5rem; gap: 1.5rem; border: 1px solid rgba(0, 0, 0, 0.1); background-color: #fff; border-left: 5px solid var(--toastColor); border-radius: 6px; box-shadow: 0 0px 12px rgba(0, 0, 0, 0.1); }
.toast .toastBox .toastStatus { display: flex; align-items: center; }
.toast .toastBox .toastStatus p { width: 36px; line-height: 36px; margin: 0 auto; font-size: 1.2rem; text-align: center; color: #fff; background-color: var(--toastColor); border-radius: 50%; }

.toast .toastBox .toastMsg { max-width: 500px; overflow: hidden; }
.toast .toastBox .toastMsg h2 { margin-bottom: 8px; font-size: 1.1rem; }
.toast .toastBox .toastMsg p { line-height: 1.6; color: rgba(0, 0, 0, 0.6); }

.toast .toastBox .toastClose { display: flex; align-items: center; }
.toast .toastBox .toastClose button { padding: 0 1rem; height: 48px; font-size: 1.8rem; color: rgba(0, 0, 0, 0.4); transition: 0.2s ease-in-out; }
.toast .toastBox .toastClose button:hover { width: 60px; border-radius: 35%; background-color: rgba(0,0,0, 0.05); color: var(--toastColor); text-shadow: 0.2rem 0.2rem rgba(0, 0, 0 ,0.1); }