@charset "UTF-8";

:root {
  --adSideMenuWidth: 250px;
}

.adSideMenu { align-self: flex-start; opacity: 0; min-width: var(--adSideMenuWidth); min-height: calc(100vh - var(--adHeaderHeight)); margin-left: calc(var(--adSideMenuWidth) * -1); padding: 2rem 0; border-right: 1px solid rgba(0, 0, 0, 0.1); box-shadow: 0 2px 4px rgba(0, 0, 0, 0.075); position: sticky; top: var(--adHeaderHeight); z-index: 100; transition: 0.5s cubic-bezier(0.25, 0.85, 0.5, 1); }
.adSideMenu.active { opacity: 1; margin: 0; }
.adSideMenu .adMenuBox { }
.adSideMenu .adMenuBox + .adMenuBox { padding-top: 2rem; }
.adSideMenu .adMenuBox > h2 { margin-bottom: 16px; padding: 0 1.5rem; font-size: 1.1rem; font-weight: 500; }
.adSideMenu .adMenuBox ul { color: #616161; }
.adSideMenu .adMenuBox ul.depth_1 > li > a > .menuTitGroup { padding-left: 34px; }
.adSideMenu .adMenuBox ul.depth_2 > li > a > .menuTitGroup { padding-left: 54px; }
.adSideMenu .adMenuBox ul li a { display: flex; justify-content: space-between; align-items: center; padding: 0 1.5rem; font-size: 0.85rem; line-height: 2.8; border-left: 1px solid transparent; transition: 0.2s ease-in-out; }
.adSideMenu .adMenuBox ul li a .menuTitGroup { display: flex; align-items: center; gap: 14px; }
.adSideMenu .adMenuBox ul li a .menuTitGroup i { font-size: 1.2rem; }
.adSideMenu .adMenuBox ul li a > i { position: relative; left: 0; transition: 0.2s ease-in-out; }
.adSideMenu .adMenuBox ul li a:is(:hover, .active) { color: #1E2734; background-color: #f5f5f5; border-left: 4px solid #1E2734; }
.adSideMenu .adMenuBox ul li a.active { background-color: #eeeeee; }
.adSideMenu .adMenuBox ul li a:is(:hover, .active) > i { left: 3px; }
.adSideMenu .adMenuBox ul li a.active > i { transform: rotate(90deg); }
