@charset "UTF-8";


/*========================================================================
* Palette Design
========================================================================*/
.layoutCont .pltGroup .plt { --size: 50px; width: var(--size); height: var(--size); margin-bottom: 20px; border-radius: 19px; box-shadow: 0 18px 20px rgba(0, 0, 0, 0.065); cursor: pointer; }


/*========================================================================
* Palette Color
========================================================================*/
/* PayGate Logo */
.pgNavy_400 strong { color: var(--pgNavy_400); }
.pgNavy_300 strong { color: var(--pgNavy_300); }
.pgNavy_200 strong { color: var(--pgNavy_200); }
.pgNavy_100 strong { color: var(--pgNavy_100); }
.pgYellow_400 strong { color: var(--pgYellow_400); }
.pgYellow_300 strong { color: var(--pgYellow_300); }
.pgYellow_200 strong { color: var(--pgYellow_200); }
.pgYellow_100 strong { color: var(--pgYellow_100); }
.pgBlack strong { color: var(--pgBlack); }
.sfrtBlue strong { color: var(--sfrtBlue_400); }

.pgNavy_400 .plt { background-color: var(--pgNavy_400); }
.pgNavy_300 .plt { background-color: var(--pgNavy_300); }
.pgNavy_200 .plt { background-color: var(--pgNavy_200); }
.pgNavy_100 .plt { background-color: var(--pgNavy_100); }
.pgYellow_400 .plt { background-color: var(--pgYellow_400); }
.pgYellow_300 .plt { background-color: var(--pgYellow_300); }
.pgYellow_200 .plt { background-color: var(--pgYellow_200); }
.pgYellow_100 .plt { background-color: var(--pgYellow_100); }
.pgBlack .plt { background-color: var(--pgBlack); }
.sfrtBlue .plt { background-color: var(--sfrtBlue_400); }

/* Text Colors */
.gray_400 strong { color: var(--gray_400); }
.gray_300 strong { color: var(--gray_300); }
.gray_200 strong { color: var(--gray_200); }
.gray_100 strong { color: var(--gray_100); }

.gray_400 .plt { background-color: var(--gray_400); }
.gray_300 .plt { background-color: var(--gray_300); }
.gray_200 .plt { background-color: var(--gray_200); }
.gray_100 .plt { background-color: var(--gray_100); }


/* System Colors */
.success strong { color: var(--successColor); }
.error strong { color: var(--errorColor); }
.info strong { color: var(--infoColor); }
.alert strong { color: var(--alertColor); }

.success .plt { background-color: var(--successColor); }
.error .plt { background-color: var(--errorColor); }
.info .plt { background-color: var(--infoColor); }
.alert .plt { background-color: var(--alertColor); }
  

/*========================================================================
* Palette Text
========================================================================*/
.layoutCont .pltGroup { display: flex; flex-direction: row; align-items: center; gap: 16px; width: 200px; }
.layoutCont .pltGroup .plt { margin: 0; }
.layoutCont .pltGroup strong { font-size: 0.85rem; font-weight: 500; }
.layoutCont .pltGroup p { margin-top: 6px; font-size: 0.75rem; color: var(--gray_400); }
