@charset "UTF-8";

/* DatePicker Styles */
.react-datepicker { display: flex !important; }
.inputIco :is(.react-datepicker-wrapper, .react-datepicker__tab-loop) + i { position: absolute; top: 50%; right: 15px; transform: translate(25%, -50%); }

/* CKEditor 5 공통 Styles */
.ck.ck-editor.ck-rounded-corners { width: 100%; }
.ck-toolbar { border-color: rgba(0, 0, 0, 0.3) !important; border-radius: 4px 4px 0 0 !important; }
.ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items { flex-wrap: wrap !important; }
.ck-editor__editable { width: 100%; min-height: 300px; max-height: 600px; line-height: 1.4; border-color: rgba(0, 0, 0, 0.3) !important; border-radius: 0 0 4px 4px !important;  }
.ck-editor__editable > .ck-placeholder::before { color: rgba(0, 0, 0, 0.5) !important; }
.ck.ck-editor__editable_inline { padding: 0 0.75rem !important; }


/*========================================================================
* Common Layout Styles
========================================================================*/
/* Admin 공통 레이아웃 */
.admLayoutGrid { flex: 1; display: flex; }
.layoutContents { flex: 1; padding: 1rem; /*overflow-y: auto;*/ }
.layoutContents.ad { padding: 2rem 2.5rem; height: calc(100vh - var(--adHeaderHeight)); background-color: #f5f5f5; overflow-y: auto; }

/* Title 영역 */
.adContTitGroup { margin-bottom: 2rem; padding: 1rem 2rem 0; }
.adContTitGroup h2 { display: flex; align-items: center; gap: 12px; margin-bottom: 8px; font-size: 1.4rem; font-weight: 700; }
.adContTitGroup .subMsg { margin-top: 12px; padding: 0.75rem; font-size: 0.8rem; background-color: #fff; border-radius: 10px; }
.adContTitGroup .subMsg li + li { margin-top: 6px; }
.titLabel { display: inline-flex; align-items: center; padding: 0 0.5rem; height: 25px; font-size: 0.85rem; color: #FFF; background-color: #222e3f; border-radius: 4px; }

/* field, box, group */
fieldset { display: flex; flex-direction: column; gap: 16px; }
fieldset.row { flex-direction: row; }
fieldset.left .fieldGroup { justify-content: flex-start; }
.fieldGroup { display: inline-flex; flex-direction: column; transition: 0.2s all ease-in-out; }
.fieldGroup.row { flex-direction: row; justify-content: center; align-items: center; }
.fieldGroup.row > label { white-space: nowrap; }
.fieldGroup.row.gap { gap: 12px; }
.fieldGroup:not(.row) > label { display: block; text-align: left; margin-bottom: 8px; }
.fieldGroup label.center { text-align: center; }
.fieldGroup.row .field + * { margin-left: 12px; font-size: 0.8rem; }
.fieldGroup > label.sm { margin-bottom: 6px; font-size: 0.8rem; }
.field { display: flex; align-items: center; position: relative; }
.field.gap { gap: 8px; }
.field.full, .field.full > * { width: 100%; }
.field.col { flex-direction: column; gap: 12px; }
.field.col.left { align-items: flex-start; }
.field.msg { display: flex; flex-direction: column; text-align: left; }
.fieldGroup:not(.row) .field + * { margin-top: 8px; }
.field + * ~ * { margin-top: 8px; }

.checkGroup { display: inline-flex; align-items: center; gap: 8px; }
.checkGroup label { cursor: pointer; user-select: none; }
.checkGroup + .checkGroup { margin-left: 16px; }


.flexBox { display: flex; align-items: center; }
.flexBox.end { align-items: flex-end; }
.flexBox.center { justify-content: center; }
.flexBox.right { justify-content: flex-end; }
.flexBox.between { justify-content: space-between; }
.flexBox.paginationBox { margin-top: 16px; padding: 0 1rem; }

.btnArea { display: flex; justify-content: center; margin-top: 40px; gap: 16px; }
.btnArea.col { flex-direction: column; }
.btnArea.full .btn { width: 100%; }
.btnArea.left { justify-content: left; }
.btnArea.right { justify-content: right; }

.tabList { display: inline-flex; align-items: center; gap: 8px; }
.redTxt { color: #ff242f; }


/*========================================================================
* Contents 영역
========================================================================*/
/* Contents 영역 공통 */
.adContArea { padding: 20px; background-color: #fff; border-radius: 12px; box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); animation: adContAreaAm 0.3s ease-in-out forwards; }
.adContArea.on { animation: dtUpdate 0.3s ease-in-out forwards; }
.adContArea.off { animation: dtUpdate_2 0.3s ease-in-out forwards; }
.adContArea .table table tr .checkGroup { position: relative; top: 2px; }
.adContArea .table table thead tr th { border-top-color: var(--sfrtBlue_300); border-bottom-color: var(--sfrtBlue_400); transition: 0.2s ease-in-out; user-select: none; }
.adContArea .table table thead tr th:hover { color: var(--sfrtBlue_400); cursor: pointer; }
.adContArea .table table tbody tr { transition: 0.2s ease-in-out; }
.adContArea .table table tbody tr:hover { background-color: #f7f7f7; }
.adContArea .table table tbody tr td { border-bottom-color: var(--sfrtBlue_100); }
.adContArea .table table tbody tr td .innerBox { display: -webkit-box; max-height: 200px; -webkit-box-orient: vertical; -webkit-line-clamp: 7; overflow: hidden; }
.adContArea .table table tbody tr td .btnArea { gap: 4px; margin: 0; }
.adContArea .table table tbody tr td img { max-height: 80px; }
.adContArea .table table tbody tr td.full > * { width: 100%; }

@keyframes adContAreaAm {
  0% { opacity: 0; transform: translateY(10px); }
  100% { opacity: 1; transform: translateY(0); }
}
@keyframes dtUpdate {
  0% { opacity: 0.1; transform: translateX(-20px); }
  100% { opacity: 1; transform: translateX(0); }
}
@keyframes dtUpdate_2 {
  0% { opacity: 0.1; transform: translateX(-20px); }
  100% { opacity: 1; transform: translateX(0); }
}

/* List Contents 페이지 */
.adContArea.list .flexBox:not(.paginationBox) { margin-bottom: 20px; padding: 0 1rem; }
.adContArea.list .adListBox { display: flex; align-items: center; gap: 12px; }

/* Detail Contents 페이지 */
.adContArea.detail { padding: 0 20px 40px; }
.adContArea.detail .fieldGroup > label { min-width: 140px; }
.adContArea.detail .btnArea > .btn { width: 200px; }
.adContArea.detail fieldset { gap: 0 }
.adContArea.detail fieldset .fieldGroup { padding: 18px 28px; border-bottom: 1px dashed rgba(0, 0, 0, 0.2); }
.adContArea.detail fieldset .fieldGroup > label span { margin-left: 4px; } 
.adContArea.detail fieldset .fieldGroup:last-child { border-bottom: 1px solid rgba(0, 0, 0, 0.4); }
.adContArea.detail .tagList { display: flex; gap: 8px; margin-left: 8px; }
.adContArea.detail .tagList p { padding: 0 1rem; line-height: 30px; font-size: 0.8rem; border: 1px solid rgba(0, 0, 0, 0.6); border-radius: 18px; }
.adContArea.detail .tagList p button { margin-left: 4px; }
