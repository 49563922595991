@charset "UTF-8";

.forbidden { display: flex; justify-content: center; align-items: center; height: 100%; padding: 2rem; background-color: var(--primary_200); }
.forbidden .inner { display: flex; justify-content: center; gap: 60px; position: relative; max-width: 700px; width: 100%; padding: 4rem 4rem 4rem 3rem; animation: forbMotionY 0.7s ease-in-out forwards; transform: translateY(15%); opacity: 0; box-shadow: 20px 20px 30px var(--primary); border-radius: 16px; background-color: #fff; }
.forbidden .inner > :is(.closeIco, span) { position: absolute; top: 24px; }
.forbidden .inner .closeIco { right: 24px; }
.forbidden .inner .closeIco:before { content: "\eee4"; font-family: Icofont; font-size: 1.4rem; transition: 0.2s all ease-in-out; }
.forbidden .inner .closeIco:hover:before { color: var(--primary_200);}
/* .forbidden .inner > span { background-color: rgba(0, 0, 100, 0.05); padding: 0.75rem 1.25rem; border-radius: 16px; left: 24px; color: var(--primary); font-size: 0.9rem; font-weight: 600; } */
.forbidden .inner > span { left: 24px; color: var(--primary); font-size: 1rem; font-weight: 600; }

.forbidden .inner > div { display: flex; justify-content: center; flex-direction: column; flex: 1; }
.forbidden .inner > div { animation: forbMotionX 0.7s ease-in-out forwards; transform: translateX(25%); opacity: 0; }

.forbidden .inner .forbIco { align-items: center; }
.forbidden .inner .forbIco > i { color: rgba(0, 0, 0, 0.7); font-size: 10rem; text-shadow: 1.6rem 1.6rem rgba(0, 0, 150, 0.1); }

.forbiddne .inner .forbTxtGroup:hover { background-color: #000;  }
.forbidden .inner .errCode { margin-bottom: 8px; letter-spacing: 2px; font-size: 4rem; font-weight: 600; }
.forbidden .inner .errTit { margin-bottom: 20px; font-size: 1.6rem; font-weight: 600; }
.forbidden .inner .txtMsg { line-height: 1.4; }
.forbidden .inner .btn { margin-top: 24px; }
.forbidden .inner .btn > i { position: relative; top: 2px; margin-right: 6px; font-size: 1.4rem; }

@keyframes forbMotionY {
  to { transform: translateY(0); opacity: 1;  }
}
@keyframes forbMotionX {
  to { transform: translateX(0); opacity: 1;  }
}