@charset "UTF-8";

/*========================================================================
* Table Common Styles
========================================================================*/
.table { width: 100%; text-align: left; overflow-y: auto; line-height: 1.6; /*box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);*/ }
table { min-width: 100%; border-collapse: collapse; font-size: 0.8rem; }
table.center { text-align: center; }
table i { margin: 0 6px; }
table thead tr { white-space: nowrap; }
table thead tr th { padding: 0.7rem 1rem; font-size: 0.85rem; font-weight: 500; }
table thead tr th.check { width: 0; }
table thead tr th:first-child { border-radius: 8px 0 0 0; }
table thead tr th:last-child { border-radius: 0 8px 0 0; }
table tbody tr:nth-child(2n) { background-color: #fdfdfd; }
table tbody tr.active { background-color: #e6eeff; }
/* .table tbody tr:hover { background-color: #e9e9e9; } */
table tbody tr td { padding: 0.5rem 1rem; border-bottom: 1px solid rgba(0, 0, 0, 0.125); overflow: hidden; text-overflow: ellipsis; }
table tbody tr:last-child td:first-child { border-radius: 0 0 0 8px; }
table tbody tr:last-child td:last-child { border-radius: 0 0 8px 0px; }


/*========================================================================
* Type_1
========================================================================*/
.table.list_1 thead tr th { color: #fff; background-color: #444; }


/*========================================================================
* Type_2
========================================================================*/
/* .table.list_2 { padding: 1rem; } */
.table.list_2 thead tr th { border-top: 2px solid rgba(0, 0, 0, 0.4); border-bottom: 1px solid rgba(0, 0, 0, 0.4); }
/* .table.list_2 tbody tr:hover { background-color: #f0f0f0; } */


/*========================================================================
* No data styles
========================================================================*/
.noData { padding: 2rem 0; text-align: center; line-height: 2; }
.noData i { font-size: 2rem; margin-bottom: 60px; }
.noData p { font-size: 0.9rem; }