@charset "UTF-8";

/*========================================================================
* Enabling
========================================================================*/
.perfWrap { position: absolute; top: 0; width: 100%; padding: 4rem 1.2rem; text-align: center; color: #fff; background-color: #222; }
.perfTxt { font-size: 1.3rem; letter-spacing: 12px; text-shadow: 0.2rem 0.2rem rgba(255, 255, 255, 0.2); }

@media screen and (max-width: 1280px) {
  .perfTxt { font-size: 1.2rem; letter-spacing: 10px;}
}
@media screen and (max-width: 767px) {
  .perfWrap { padding: 3rem 1.2rem; }
  .perfTxt { font-size: 1rem; letter-spacing: 8px;}
}
@media screen and (max-width: 550px) {
  .perfWrap { padding: 2.4rem 1.2rem; }
  .perfTxt { line-height: 1.6; font-size: 0.8rem; letter-spacing: 4px; }
}
@media screen and (max-width: 360px) {
  .perfWrap { padding: 1.6rem 1.2rem; }
}

/*========================================================================
* Why Neoframe?
========================================================================*/
.reason, .news { display: flex; flex-wrap: wrap; }
.reason { gap: 20px; }
.news { gap: 15px 30px; margin-bottom: 30px; }
.reasonItem { display: flex; justify-content: center; align-items: center; width: calc((100% / 3) - 13.33px); padding: 3rem; text-align: center; border: 3px solid #ccc; border-radius: 4px; background-color: #f8f8f8; line-height: 1.6; font-size: 1.2rem; font-weight: 500; transition: 0.3s all ease-in-out }
.newsItem { display: flex; align-items: center; border-radius: 4px; width: calc((100% / 2) - 15px); padding: 0.75rem; text-align: left; line-height: 1.6; color: #fff; background-color: #222; }

@media screen and (max-width: 1280px) {
  .reasonItem { padding: 2.2rem; }
}
@media screen and (max-width: 1024px) {
  .reasonItem { padding: 1.5rem; font-size: 1rem; }
}
@media screen and (max-width: 767px) {
  :is(.reason, .news) { gap: 10px; }
  .reasonItem { width: calc((100% / 2) - 4.99px); padding: 1.5rem; font-size: 0.9rem; }
  .newsItem { width: calc((100% / 2) - 8px); }
}
@media screen and (max-width: 550px) {
  .reasonItem { padding: 1rem; font-size: 0.8rem; }
  .newsItem { font-size: 0.8rem; }
}
@media screen and (max-width: 360px) {
  :is(.reasonItem, .newsItem) { width: 100%; }
}

/*========================================================================
* Contact Us
========================================================================*/
.contactTxt + .contactTxt { margin-top: 50px; }
.contactTxt .infoTxt + .infoTxt { margin-top: 0 }
.contactField { margin-top: 20px; padding: 2rem; border: 1px solid #ccc; border-radius: 10px;  }
.contactField > * + * { margin-top: 20px; }
.contactField .field { display: flex; gap: 20px; }
.contactField > .btnArea button { width: 100%; }

@media screen and (max-width: 767px) {
  .contactField { padding: 1.5rem; }
  .contactField > * + * { margin-top: 10px; }
}
@media screen and (max-width: 550px) {
  .contactField { padding: 1.25rem; }
  .contactField .select { min-width: 100%; }
  .contactField .field { flex-wrap: wrap; gap: 10px;}
}


/*========================================================================
* Popup Styles
========================================================================*/
.popupGrid .popupBox { display: flex; flex-direction: column; position: absolute; top: calc(var(--logoSize) + 5%); left: 3%; z-index: 10; border-radius: 2px; box-shadow: 0 2px 5px rgba(0,0,0,0.3); }
.popupGrid .popupBox img { max-height: 700px; }
.popupGrid .popupBox .closeBox { display: flex; gap: 12px; padding: 0.25rem 0.5rem; font-weight: 400; background-color: #fff; border-top: 1px solid rgba(0,0,0,0.2); border-radius: 0 0 2px 2px; }
.popupGrid .popupBox .closeBox.right { justify-content: flex-end; }
.popupGrid .popupBox .closeBox > label { user-select: none; cursor: pointer; }
.popupGrid .popupBox .closeBox > input { position: relative; top: 1px; } 
.popupGrid .popupBox .closeBox > * { display: flex; align-items: center; gap: 4px; }
.popupGrid .popupBox .closeBox > * > :is(label, button):hover { font-weight: bold;  }

@media screen and (max-width: 767px) {
  .popupGrid { display: flex; justify-content: center; margin: 0 12px; position: absolute; top: 0; right: 0; bottom: 0; left: 0; }
  .popupGrid .popupBox { left: 0; }
  .popupGrid .popupBox .closeBox { font-size: 0.8rem; }
}