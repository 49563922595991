@charset "UTF-8";

/*========================================================================
* Message Box Styles
========================================================================*/
.msgBox { position: relative; max-width: 300px; padding: 1rem 2rem 1rem 1rem; word-break: break-all; border-radius: 4px; box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15); }
.msgBox > h2 { margin-bottom: 8px; line-height: 1.3; }
.msgBox > p { font-size: 0.75rem; color: #bebebe; }
.msgBox:not(.black) > p { color: rgba(0, 0, 0, 0.6); }

.msgBox:before { content: ''; width: 8px; height: 8px; position: absolute; background-color: #fff; transform: rotate(45deg); }
.msgBox.black { color: #fff; background-color: #2c3e58; } /* Basic: White */
.msgBox.black:before { background-color: #2c3e58; }

.msgBox.top:before { top: -4px; }
.msgBox.right:before { right: -4px; }
.msgBox.bottom:before { bottom: -4px; }
.msgBox.left:before { left: -4px; }


/*========================================================================
* CheckBox, Radio Button Styles
========================================================================*/
input.check[type="checkbox"] { display: none; }
input.check[type="radio"] { display: none; } /* 기존 input 태그 가리기 */

:root {

}


/* CheckBox Size [xs, sm, md, lg, xl] */
input.check.xs + label { --checkWidth: 12px; --checkHeight: 12px; }
input.check.xs + label:after { top: 1px; font-size: 0.7rem; }
input.check.sm + label { --checkWidth: 16px; --checkHeight: 16px; }
input.check.sm + label:after { top: 1px; font-size: 0.9rem; }
input.check.md + label { --checkWidth: 20px; --checkHeight: 20px; }
input.check.md + label:after { font-size: 1.2rem; }
input.check.lg + label { --checkWidth: 24px; --checkHeight: 24px; }
input.check.lg + label:after { top: 1px; font-size: 1.4rem; }
input.check.xl + label { --checkWidth: 28px; --checkHeight: 28px; }
input.check.xl + label:after { top: 1px; font-size: 1.6rem; }

/* CheckBox Color [red, black, blue, purple] */
input.check.red + label { --checkColor: var(--errorColor); }
input.check.black + label { --checkColor: rgba(0, 0, 0, 0.7); }
input.check.blue + label { --checkColor: var(--sfrtBlue_400);}
input.check.purple + label { --checkColor: var(--pgNavy_400); }

/* CheckBox Checked Style */
input.check:is([type="checkbox"]) + label { display: inline-block; position: relative; width: var(--checkWidth); height: var(--checkHeight); border: 1px solid var(--gray_400); transition: 0.2s ease-in-out }
input.check[type="checkBox"] + label { padding: 0; border-radius: 2px; }
input.check[type="checkbox"]:checked + label { color: #fff; background-color: var(--checkColor); border: 0; }
input.check[type="checkbox"]:checked + label:after { content: "\eed8"; position: absolute; width: 100%; font-family: IcoFont; color: #fff; text-align: center; line-height: 1; transform: scale(1.1); }

input.check[type="radio"] + label { display: inline-flex; align-items: center; position: relative; }
input.check[type="radio"] + label:before { content: ""; display: inline-block; margin-right: 4px; width: var(--checkWidth); height: var(--checkHeight); border: 1px solid var(--gray_400); border-radius: 50%; }
input.check[type="radio"] + label:after { content: ''; opacity: 0; width: calc(var(--checkWidth) - 4px); height: calc(var(--checkHeight) - 4px); position: absolute; top: 3px; left: 3px; border-radius: 50%; background-color: var(--checkColor); transform: scale(0); transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275); }
input.check[type="radio"]:checked + label:before { border-color: var(--checkColor); }
input.check[type="radio"]:checked + label:after { opacity: 1; transform: scale(1); }

input.check:is([type="checkbox"], [type="radio"]):disabled + label { border-color: rgba(0, 0, 0, 0.25); cursor: default; }
input.check[type="checkbox"]:checked:disabled + label { background-color: rgba(0, 0, 0, 0.25); }
input.check[type="radio"]:checked:disabled + label:after { background-color: rgba(0, 0, 0, 0.25); }


/*========================================================================
* Toggle Button Styles
========================================================================*/
input.switchBtn[type="checkbox"] { display: none; }

input.switchBtn + label { display: flex; align-items: center; justify-content: space-between; width: var(--switchWidth); height: var(--switchSize); position: relative; border: 1px solid rgba(0, 0, 0, 0.6); cursor: pointer; transition: 0.3s ease-in-out; }
input.switchBtn + label .switchCircle { z-index: 10; display: inline-block; width: calc(var(--switchSize) - 6px); height: calc(var(--switchSize) - 6px); position: relative; right: 0; left: 2px; border-radius: var(--switchSize); background-color: #2c3e58; transition: 0.2s ease; }
input.switchBtn:not(:disabled) + label:active .switchCircle { width: calc(var(--switchSize) + (var(--switchSize) - 24px)); }
input.switchBtn + label span { position: absolute; right: 14px; line-height: var(--switchSize); font-size: var(--switchFontSize); transition: 0.2s ease-in-out; }
input.switchBtn:checked + label { background-color: var(--switchColor); border-color: var(--switchColor); }
input.switchBtn:checked + label .switchCircle { left: 97%; transform: translateX(-97%); background-color: #fff; }
input.switchBtn:checked + label span { right: calc(100% - var(--switchPos)); color: #fff; }
input.switchBtn:disabled + label { opacity: 0.8; color: rgba(0, 0, 0, 0.5); border-color: rgba(0, 0, 0, 0.5); }
input.switchBtn:disabled + label .switchCircle { background-color: rgba(0, 0, 0, 0.5); }

/* Toggle Size */
input.switchBtn.xs + label { --switchSize: 22px; --switchWidth: 60px; --switchFontSize: 0.65rem; --switchPos: 24px; }
input.switchBtn.sm + label { --switchSize: 26px; --switchWidth: 70px; --switchFontSize: 0.7rem; --switchPos: 26px; }
input.switchBtn.md + label { --switchSize: 30px; --switchWidth: 80px; --switchFontSize: 0.75rem; --switchPos: 28px; }
input.switchBtn.lg + label { --switchSize: 34px; --switchWidth: 90px; --switchFontSize: 0.8rem; --switchPos: 30px; }
input.switchBtn.xl + label { --switchSize: 38px; --switchWidth: 100px; --switchFontSize: 0.8rem; --switchPos: 32px; }

/* Toggle Round */
input.switchBtn.round_10 + label { border-radius: 4px; }
input.switchBtn.round_100 + label { border-radius: 40px; }

/* Toggle Color */
input.switchBtn.blue + label { --switchColor: var(--sfrtBlue_400); }
input.switchBtn.red + label { --switchColor: var(--errorColor); }

/* Toggle Fill Styles */
input.switchBtn.fill:not(:checked) + label { background-color: rgba(0, 0, 0, 0.3); border-color: rgba(0, 0, 0, 0.01); }
input.switchBtn.fill:not(:checked) + label .switchCircle { background-color: #fff; }
input.switchBtn.fill:not(:checked) + label span { color: #fff; }
input.switchBtn.fill:disabled + label { opacity: 0.4; }

/*========================================================================
* Tag Box Style
========================================================================*/
.tagBox { display: flex; justify-content: space-between; align-items: center;  background-color: var(--sfrtBlue_100); border: 1px solid var(--sfrtBlue_100); transition: 0.2s ease-in-out; }
.tagBox button { position: relative; top: 1px; transition: 0.2s ease-in-out; }
.tagBox button:hover { color: var(--sfrtBlue_400); }

.tagBox.xs { gap: 8px; height: 24px; padding: 0 0.8rem; font-size: 0.75rem; }
.tagBox.xs button { font-size: 0.95rem; }
.tagBox.sm { gap: 12px; height: 30px; padding: 0 0.9rem; font-size: 0.8rem;  }
.tagBox.sm button { font-size: 1rem; }
.tagBox.md { gap: 16px; height: 34px; padding: 0 1rem; font-size: 0.85rem; } 
.tagBox.md button { font-size: 1.05rem; } 
.tagBox.lg { gap: 20px; height: 38px; padding: 0 1.1rem; font-size: 0.9rem; }
.tagBox.lg button { font-size: 1.1rem; }
.tagBox.xl { gap: 24px; height: 42px; padding: 0 1.2rem; font-size: 0.95rem; }
.tagBox.xl button {font-size: 1.15rem; }

.tagBox.round_10 { border-radius: 4px; }
.tagBox.round_100 { border-radius: 40px; }

.tagBox.edge { border: 1px solid rgba(0, 0, 0, 0.7); background-color: #fff; transition: 0.3s ease-in-out; }
.tagBox.edge button:hover { color: var(--sfrtBlue_400) }
