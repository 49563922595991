@charset "UTF-8";

/*==========================================================================
* Header
==========================================================================*/
:root {
  --logoSize: 120px;
}

header { background-color: #FFF; box-shadow: 0 0.5rem 0.5rem -0.25rem rgba(0,0,0,0.2); position: sticky; top: 0; z-index: 100; }
header .inner { display: flex; align-items: center; height: var(--logoSize); padding: 1rem 1.6rem; position: relative; }

header nav { margin: 0 auto; }
header nav a { display: block; padding: 0 8px; font-size: 0.9rem; color: #3d9be9; transition: 0.2s ease-in-out; }
header nav ul:not(.active) a:hover { color: var(--defaultGray); }
header :is(.mobileBtn:is(:after, :before)).active { background-color: #fff; }

header .langGroup { position: relative; }
header .langGroup button img { position: relative; top: 2px; max-height: 20px; }
header .langGroup button { display: flex; align-items: center; gap: 8px; }
header .langGroup button i { font-size: 1rem; transition: 0.1s ease-in-out; }
header .langGroup button.active i { transform: rotate(180deg); }
header .langGroup button + ul { position: absolute; top: 30px; right: 0; width: 100%; font-size: 0.8rem; background-color: #fff; border: 1px solid rgba(0, 0, 0, 0.15); border-radius: 4px; cursor: pointer; }
header .langGroup button + ul li { padding: 0.7rem 0.5rem; }
header .langGroup button + ul li:hover { background-color: rgba(0, 0, 0, 0.05); }
header .langGroup button + ul li + li { border-top: 1px solid rgba(0, 0, 0, 0.05); }


@media screen and (max-width: 1280px) {
  header nav a { padding: 0 6px; }
}
@media screen and (min-width: 1025px) {
  header nav ul { display: flex; align-items: center; }
  header nav ul li + li { margin-left: 16px; }
}
@media screen and (max-width: 1024px) {
  :root {
    --logoSize: 60px;
  }
  header .inner { padding: 1rem 0.8rem; height: var(--logoSize); }
  header a img { max-height: calc(var(--logoSize) - 1rem); }
  header nav { order: 3; margin: unset }
  header nav button { opacity: 0.9; }
  header nav ul { z-index: 10; padding-top: var(--logoSize); height: 100%; opacity: 0; transform: translateX(200px); backdrop-filter: blur(4px); position: fixed; top: 0; right: 0; background-color: rgba(0,0,0,0.5); transition: all 0.4s ease-in-out; }
  header nav ul a { color: #fff; }
  header nav li { padding: 1rem; }
  header nav li:hover { color: #fff; background-color: rgba(255, 255, 255, 0.2); }

  header nav .mobileBtn { display: block; width: 24px; height: 24px; position:relative; z-index: 100; }
  header nav .mobileBtn:before,
  header nav .mobileBtn:after { content: ""; display: block; margin: auto; width: 100%; height: 3px; border-radius: 2px; background-color: #333; position: absolute; top: 0; right: 0; bottom: 0; left: 0; }
  header nav .mobileBtn:after { top: 16px; }  
  header nav .mobileBtn:before { top: -16px; }
  header nav .mobileBtn:not(.active):before { box-shadow: 0 8px 0px #333; }
  header nav .mobileBtn.active:before,
  header nav .mobileBtn.active:after { top: 0; transform: rotate(45deg); background-color: #fff; }
  header nav .mobileBtn.active:after { transform: rotate(-45deg); }
  header nav .mobileBtn.active + ul { opacity: 1; transform: translateX(0); transition: 0.4s ease-in-out; }

  header .langGroup { flex: 1; margin-right: 24px; }
  header .langGroup button { margin-left: auto; }
  header .langGroup button + ul { width: 100px;}
}
