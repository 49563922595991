
/*==========================================================================
* About
==========================================================================*/
.visual.about { height: 665px; background-image: url("../../img/about/about_visual.jpg"); background-position-y: bottom; }

/* 임시 소개 Styles */
.contTit.kor { font-size: 2rem; font-weight: 600; line-height: 1.3; letter-spacing: 1px; color: #606060; }
.contTit.kor span { color: #14539A; }
.introduceList ul { display: flex; max-width: 600px; margin: 0 auto; }
.introduceList ul li { display: flex; flex-direction: column; justify-content: center; align-items: center; flex: 1; gap: 12px; padding: 1rem; text-align: center; }
.introduceList ul li img { max-width: 75px; }
.introduceList ul li p { font-weight: 500; line-height: 1.4; color: #14639A; }

.visual.kor { min-height: 500px; max-height: 1000px; background-image: url("../../img/about/about_banner.png"); background-position-y: bottom;}
.visual.kor .inner { display: flex; flex-direction: column; align-items: flex-start; padding: 4rem 2rem; }
.visual.kor .inner > h2 { margin-bottom: 60px; font-size: 1.8rem; font-weight: 400; line-height: 1.3; color: #06A0FF; }
.visual.kor .inner > ul { display: flex; flex-direction: column; color: #fff; }
.visual.kor .inner > ul li { max-width: 600px; word-break: keep-all; }
.visual.kor .inner > ul li + li { margin-top: 24px; }
.visual.kor .inner > ul li h3 { font-size: 1.7rem; font-weight: 400; }
.visual.kor .inner > ul li p { font-size: 1rem; line-height: 1.4; opacity: 0.9; }
.visual.kor .inner > ul li > * + * { margin-top: 8px; }

@media screen and (max-width: 1280px) {
  .visual.kor .inner > ul li p { font-size: 0.9rem; }
}

@media screen and (max-width: 1024px) {
  .contTit.kor { font-size: 1.7rem }
}

@media screen and (max-width: 767px) {
  .contTit.kor { font-size: 1.4rem }
}

@media screen and (max-width: 550px) {
  .visual.kor { display: flex; align-items: center; min-height: 420px; }
  .visual.kor .inner { margin: 0; }
  .visual.kor .inner > h2 { margin-bottom: 30px; font-size: 1.6rem; }
  .visual.kor .inner > ul li h3 { font-size: 1.5rem; }
  .visual.kor .inner > ul li p { font-size: 0.8rem; }
}

@media screen and (max-width: 360px) {
  .introduceList ul li img { max-width: 120px; }
  .introduceList ul { flex-direction: column; }
}

.experienceInfo { color: #FFF; background-color: var(--primary); }
.experienceInfo .inner { display: flex; flex-wrap: wrap; justify-content: center; gap: 20px; text-align: center; }
.experienceInfo .inner .cnt { width: calc((100% / 3) - 13.33px);}
.experienceInfo .inner .cnt .num { margin-bottom: 20px; font-size: 4rem; }
.experienceInfo .inner .cnt span { display: block; padding: 4px 8px; opacity: 0.6; }

.partners .logoGroup { display: flex; flex-wrap: wrap; gap: 1px; }
.partners .logoGroup figure { display: inline-flex; justify-content: center; align-items: center; width: calc(100% / 3 - 1px); height: 100px; text-align: center; border: 1px solid #EEE; }
.partners .logoGroup figure img { max-width: 150px; }

@media screen and (max-width: 1280px) {
  .visual.about {height: 700px; }
}
@media screen and (max-width: 1024px) {
  .visual.about {height: 600px; }
}
@media screen and (max-width: 767px) {
  .visual.about {height: 500px; }
  .partners .logoGroup figure { width: calc((100% / 2) - 2px)}
}
@media screen and (max-width: 550px) {
  .visual.about { height: 450px; }
  .experienceInfo .inner .cnt .num { margin-bottom: 15px; font-size: 3rem; }
  .experienceInfo .inner .cnt span { padding: 2px 8px; }
  .partners .logoGroup figure img { max-width: 120px; }
}
@media screen and (max-width: 360px) {
  .visual.about { height: 400px; }
  :is(.experienceInfo .inner .cnt, .partners .logoGroup figure) { width: 100%; }
  .partners .logoGroup figure img { max-width: 140px; }
}

