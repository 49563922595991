@charset "UTF-8";

:root {
    --adHeaderHeight: 70px;
}

.adHeader { display: flex; justify-content: space-between; align-items: center; min-height: var(--adHeaderHeight); padding: 0 1.5rem; background-color: #1E2734; color: #fff; position: sticky; top: 0; z-index: 100; }

.adHeader .adInnerHdr { display: flex; align-items: center; gap: 24px;  }
.adHeader .adInnerHdr > button { font-size: 1.2rem; }

.adHeader .adInnerHdr .sfrtLogo { font-size: 0.9rem; font-weight: 300; color: #dbdbdb; }
.adHeader .adInnerHdr .sfrtLogo img { height: 28px; margin-right: 6px; }
.adHeader .adInnerHdr .sfrtLogo span { position: relative; top: -1px; }
.adHeader .adInnerHdr .sfrtLogo span strong { font-size: 1.1rem; margin-right: 2px; }

.adHeader .accArea { display: flex; align-items: center; gap: 20px; }
.adHeader .accArea .sessionGroup { display: flex; align-items: center; gap: 16px; }
.adHeader .accArea .adProfile i { margin-right: 8px;  }
.adHeader .accArea button:hover { text-decoration: underline; text-underline-position: under; }