@charset "UTF-8";

.login { display: flex; justify-content: center; align-items: center; height: 100%; padding: 0 2rem; }
.login .inner { max-width: 400px; width: 100%; padding: 2.5rem; text-align: center; border: 1px solid rgba(0, 0, 0, 0.3); border-radius: 8px;  }
.login .inner .logoGroup { display: flex; justify-content: center; align-items: flex-end; gap: 8px; margin-bottom: 28px; }
.login .inner .logoGroup img { max-height: 40px; }

.login .inner .logoGroup h2 { font-size: 1.2rem; color: #484848 }
.login .inner .logoGroup h2 + * { margin-top: 40px; }

.login .inner .fieldGroup > label { width: 150px; text-align: left; }
.login .inner .fieldGroup > label span { margin-left: 4px; }

.login .inner .field span { margin-right: 8px; color: rgba(0, 0, 0, 0.7); font-size: 1.3rem; }
.login .inner .field input { width: 100%; }
.login .inner .fieldTxt { margin-top: 8px; }