@charset "UTF-8";

/*========================================================================
* Font
========================================================================*/
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url(_Asset/font/NotoSansKR/NotoSansKR-Thin.woff2) format('woff2'),
       url(_Asset/font/NotoSansKR/NotoSansKR-Thin.woff) format('woff'),
       url(_Asset/font/NotoSansKR/NotoSansKR-Thin.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url(_Asset/font/NotoSansKR/NotoSansKR-Light.woff2) format('woff2'),
       url(_Asset/font/NotoSansKR/NotoSansKR-Light.woff) format('woff'),
       url(_Asset/font/NotoSansKR/NotoSansKR-Light.otf) format('opentype');
}
@font-face {
   font-family: 'Noto Sans KR';
   font-style: normal;
   font-weight: 400;
   src: url(_Asset/font/NotoSansKR/NotoSansKR-Regular.woff2) format('woff2'),
        url(_Asset/font/NotoSansKR/NotoSansKR-Regular.woff) format('woff'),
        url(_Asset/font/NotoSansKR/NotoSansKR-Regular.otf) format('opentype');
 }
@font-face {
   font-family: 'Noto Sans KR';
   font-style: normal;
   font-weight: 500;
   src: url(_Asset/font/NotoSansKR/NotoSansKR-Medium.woff2) format('woff2'),
        url(_Asset/font/NotoSansKR/NotoSansKR-Medium.woff) format('woff'),
        url(_Asset/font/NotoSansKR/NotoSansKR-Medium.otf) format('opentype');
 }
@font-face {
   font-family: 'Noto Sans KR';
   font-style: normal;
   font-weight: 700;
   src: url(_Asset/font/NotoSansKR/NotoSansKR-Bold.woff2) format('woff2'),
        url(_Asset/font/NotoSansKR/NotoSansKR-Bold.woff) format('woff'),
        url(_Asset/font/NotoSansKR/NotoSansKR-Bold.otf) format('opentype');
 }
@font-face {
   font-family: 'Noto Sans KR';
   font-style: normal;
   font-weight: 900;
   src: url(_Asset/font/NotoSansKR/NotoSansKR-Black.woff2) format('woff2'),
        url(_Asset/font/NotoSansKR/NotoSansKR-Black.woff) format('woff'),
        url(_Asset/font/NotoSansKR/NotoSansKR-Black.otf) format('opentype');
 } 

/*========================================================================
* Reset
========================================================================*/
* { margin: 0; padding: 0; font-family: inherit; font-size: 100%; border: 0; outline: 0; box-sizing: border-box; }
ul, ol { list-style: none; }
img { max-width: 100%; }
legend { display: none; }
a { color: inherit; text-decoration: none; }
button { color: inherit; background: transparent; cursor: pointer; }
::placeholder { position: relative; top: -1px; color: rgba(0,0,0,0.5); font-size: 0.9rem; }
::-webkit-scrollbar { width: 2px; }
::-webkit-scrollbar-thumb { width: 2px; background-color: rgba(0,0,0,0.3); border-radius: 2px; }
::-webkit-scrollbar-track { background-color: none; }


/*========================================================================
* Default Set
========================================================================*/
html, 
body { height: 100%; scroll-behavior: smooth; }
body { font: 14px/1 'Noto Sans KR', sans-serif; font-weight: 400;  color: var(--defaultGray); word-break: keep-all; }
#root { height: 100%; }

:root {
  --defaultGray: #303030;
  --primary: #14539a; --primary_200: #3b689b;
  --gold: #bfad7f;

  /* Paygate Logo Colors  */
  --pgNavy_400: #262162; /* Main pgNavy */
  --pgNavy_300: #545081; /* Dark pgNavy */
  --pgNavy_200: #726da1; /* pgNavy */
  --pgNavy_100: #9d9bbd; /* Light pgNavy */

  --pgYellow_400: #dfad1a; /* Main pgYellow */
  --pgYellow_300: #dfc260; /* Dark pgYellow */
  --pgYellow_200: #dfc98a; /* pgYellow */
  --pgYellow_100: #e0d5b4; /* Light pgYellow */
  
  --pgBlack: #2C2728;

  --sfrtBlue_400: #4172B5; /* Main sfrtBlue */
  --sfrtBlue_300: #6088c0; /* Dark sfrtBlue */
  --sfrtBlue_200: #8caad4; /* sfrtBlue */
  --sfrtBlue_100 : #cad7eb; /* Light sfrtBlue */

  
  /* Text Colors */
  --gray_400: #2C2728; /* Main Gray */
  --gray_300: #666666; /* Dark Gray */
  --gray_200: #999999; /* Gray */
  --gray_100: #CCCCCC; /* Light Gray */


  /* System Colors  */
  --successColor: #39AF8A;
  --errorColor: #FF3C45;
  --infoColor: #4172B5;
  --alertColor: #dfad1a;

  --warningColor: #dfad1a;
  --noticeColor: #4172B5;
  --toastInfoColor: #7C94A2;
}
