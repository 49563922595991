@charset "UTF-8";

/*========================================================================
* Select Box Styles
========================================================================*/
/* Select Common */
.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  padding: 0 3rem 0 1rem; height: 35px; font-size: 0.9rem; color: var(--gray_400); border: 1px solid rgba(0, 0, 0, 0.3); transition: 0.2s ease-in-out;
}
.select.full { width: 100%; }
.select::-ms-expand { display: none; } /* IE 기본 화살표 제거 */
.select option { padding: 12px; font-size: 0.8rem; }

/* Select Size */
.select.sm { padding: 0 1.75rem 0 0.75rem; height: 30px; font-size: 0.75rem; }
.select.sm option { font-size: 0.7rem; }
.select.md { padding: 0 2rem 0 0.75rem; height: 35px; font-size: 0.825rem; }
.select.md option { font-size: 0.775rem; }
.select.lg { padding: 0 2.25rem 0 0.75rem; height: 40px; font-size: 0.9rem; }
.select.lg option { font-size: 0.8rem; }

/* Select Round */
.select.round_10 { border-radius: 4px; }
.select.round_100 { border-radius: 40px; }

/* Select Types */
.select:disabled { background-color: #f1f1f1 }
.select:is(:hover, .hover):not(:read-only, :disabled, .error, .success) { border-color: #777; }
.select:is(:focus, .focus):not(:read-only, :disabled, .error, .success) { border-color: var(--sfrtBlue_400); box-shadow: 0 5px 10px rgba(0, 0, 0, 0.075); }
.select:is(:disabled, :read-only)::placeholder { color: rgba(0,0,0,0.5)}

.select.line.white { background-color: transparent; }
.select.line.i_rt { padding-left: 0; }
.select.line:disabled { background-color: #fff; opacity: 0.7; }

.select:is(.line_bg, .line_none) { background-color: rgba(0,0,0,0.05); }
.select:is(.line_bg, .line_none).white { background-color: #fff; }
.select:is(.line_bg, .line_none):read-only { opacity: 0.7; }
.select:is(.line_bg, .line_none):disabled { background-color: var(--gray_200); }

.select.line_none { border-bottom: 0; }
.select.line_none.error { border: 1px solid var(--errorColor); }
.select.line_none.success { border: 1px solid var(--successColor); }

.select:is(.line, .line_bg, .line_none) { border-top: 0; border-right: 0; border-left: 0; }

.select.error { border-color: var(--errorColor); }
.select.error + i { color: var(--errorColor); }
.select.success { border-color: var(--successColor); }
.select.success + i { color: var(--successColor); }


/* Select Type 1 */
.select:is(.type_1, .type_1_line) { background-position: calc(100% - 15px) calc(1em + 2px), calc(100% - 10px) calc(1em + 2px), calc(100% - 2.1em) 50%; background-size: 5px 5px, 5px 5px, 1px 1.2em; }
.select.type_1 { background-image: linear-gradient(45deg, transparent 50%, var(--gray_300) 50%), linear-gradient(135deg, var(--gray_300) 50%, transparent 50%); }
.select.type_1_line { background-image: linear-gradient(45deg, transparent 50%, var(--gray_300) 50%), linear-gradient(135deg, var(--gray_300) 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc); }
.select.type_1.sm { background-position: calc(100% - 13px) calc(0.8em + 2px), calc(100% - 8px) calc(0.8em + 2px), calc(100% - 2.1em) 50%; }
.select.type_1:focus { background-image: linear-gradient(135deg, transparent 50%, var(--gray_300) 50%), linear-gradient(45deg, var(--gray_300) 50%, transparent 50%); }
.select.type_1_line:focus { background-image: linear-gradient(135deg, transparent 50%, var(--gray_300) 50%), linear-gradient(45deg, var(--gray_300) 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc); }


/* Select Type 2 */
.select.type_2 {
  background-image:
  linear-gradient(45deg, transparent 50%, var(--gray_300) 50%),
  linear-gradient(135deg, var(--gray_300) 50%, transparent 50%);
  background-position:
  calc(100% - 15px) calc(1em + 2px),
  calc(100% - 10px) calc(1em + 2px),
  calc(100% - 2.1em) 50%;
  background-size:
  5px 5px,
  5px 5px,
  1px 1.2em;

}
.select.type_2.sm {
  background-position:
  calc(100% - 13px) calc(0.8em + 2px),
  calc(100% - 8px) calc(0.8em + 2px),
  calc(100% - 2.1em) 50%;
}
.select.type_2:focus {
  background-image:
  linear-gradient(135deg, transparent 50%, var(--gray_300) 50%),
  linear-gradient(45deg, var(--gray_300) 50%, transparent 50%)
}


/* Custom Div Select box */
/* .selectBox { position: relative; width: 250px; border: 1px solid rgba(0, 0, 0, 0.3); }
.selectBox.sm { font-size: 0.8rem; }
.selectBox.round_10 { border-radius: 4px; }
.selectBox.round_100 { border-radius: 40px; }

.selectBox ul li { display: flex; justify-content: space-between; align-items: center; }
.selectBox ul li > i { font-size: 1.2rem; color: rgba(0, 0, 0, 0.7) }
.selectBox ul li:not(:last-child) { border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.selectBox .selectHeader { height: 40px; }
.selectBox.sm .selectHeader { height: 30px; }
.selectBox .selectHeader li { height: 100%; padding: 0 0.6rem; }
.selectBox .selectHeader li:hover { border-radius: 4px 4px 0 0; }

.selectBox .selectBody { opacity: 0; height: 0; z-index: 1000; position: absolute; top: 42px; right: -1px; left: -1px; height: 0; border: 1px solid rgba(0, 0, 0, 0.35); border-radius: 4px; overflow-y: auto; transition: 0.2s ease-in-out }
.selectBox.sm .selectBody { top: 32px; }
.selectBox .selectBody.active { opacity: 1; height: 160px }
.selectBox .selectBody li { padding: 0.6rem; background-color: #fff; }
.selectBox .selectBody li:hover { background-color: #f3f3f5; }
.selectBox .selectBody li:last-child:hover { border-radius: 0 0 4px 4px; } */