@charset "UTF-8";

/*========================================================================
* Common Tab Styles
========================================================================*/
.tabGroup { position: relative; }
.tabGroup .tabBar { display: inline-flex; align-items: center; }
.tabGroup .tabBar .btn { flex: 1; width: unset; line-height: 1.2; }

.tabGroup .tabCont { padding: 1.5rem; border: 1px solid rgba(0, 0, 0, 0.1); }
.tabGroup .tabCont * { line-height: 1.7; }

.tabGroup.round_10 .tabBar,
.tabGroup.round_10 .tabBar .btn,
.tabGroup.round_10 .tabCont { border-radius: 4px; }
.tabGroup.round_50 .tabBar,
.tabGroup.round_50 .tabBar .btn,
.tabGroup.round_50 .tabCont { border-radius: 12px; }
.tabGroup.round_100 .tabBar,
.tabGroup.round_100 .tabBar .btn { border-radius: 50px; }
.tabGroup.round_100 .tabCont { border-radius: 16px; }

.tabGroup.gray .tabCont { border-color: var(--gray_200)}
.tabGroup.sfrtBlue .tabCont { border-color: var(--sfrtBlue_200); }
.tabGroup.pgNavy .tabCont { border-color: var(--pgNavy_100); }
.tabGroup.pgYellow .tabCont { border-color: var(--pgYellow_100); }

/*========================================================================
* Tab Type_1 [Basic / Basic Bg]
========================================================================*/
/* Type_1 */
.tabGroup:is(.type_1, .type_1_bg) .tabBar { box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1); }
.tabGroup.type_1 .tabBar { gap: 16px; padding: 3px; }
.tabGroup:is(.type_1, .type_1_bg) .tabCont { margin-top: 4px; }

.tabGroup.type_1.gray .tabBar { border: 1px solid var(--gray_200); background-color: #fff; }
.tabGroup.type_1.sfrtBlue .tabBar { border: 1px solid var(--sfrtBlue_200); background-color: #fff; }
.tabGroup.type_1.pgNavy .tabBar { border: 1px solid var(--pgNavy_200); background-color: #fff; }
.tabGroup.type_1.pgYellow .tabBar { border: 1px solid var(--pgYellow_200); background-color: #fff; }

.tabGroup.type_1.gray .tabBar .btn:is(.active, :hover) { color: #fff; background-color: var(--gray_400); }
.tabGroup.type_1.sfrtBlue .tabBar .btn:is(.active, :hover) { color: #fff; background-color: var(--sfrtBlue_400); }
.tabGroup.type_1.pgNavy .tabBar .btn:is(.active, :hover) { color: #fff; background-color: var(--pgNavy_400); }
.tabGroup.type_1.pgYellow .tabBar .btn:is(.active, :hover) { color: #fff; background-color: var(--pgYellow_400); }


/* Type_1_bg */
.tabGroup.type_1_bg .btn:first-child { border-top-right-radius: 0; border-bottom-right-radius: 0; }
.tabGroup.type_1_bg .btn:last-child { border-top-left-radius: 0; border-bottom-left-radius: 0; }
.tabGroup.type_1_bg .btn:not(:first-child, :last-child) { border-radius: 0; }

/* Type_1_bg - Tab Bar */
.tabGroup.type_1_bg .tabBar { background-color: #fff; }

/* Type_1_bg - Color */
.tabGroup.type_1_bg.gray .tabBar { border: 1px solid var(--gray_200); }
.tabGroup.type_1_bg.gray .tabBar .btn:hover { color: #fff; background-color: var(--gray_300); }
.tabGroup.type_1_bg.gray .tabBar .btn.active { color: #fff; background-color: var(--gray_400); }

.tabGroup.type_1_bg.sfrtBlue .tabBar { border: 1px solid var(--sfrtBlue_200) }
.tabGroup.type_1_bg.sfrtBlue .tabBar .btn:hover { color: #fff; background-color: var(--sfrtBlue_300); }
.tabGroup.type_1_bg.sfrtBlue .tabBar .btn.active { color: #fff; background-color: var(--sfrtBlue_400); }

.tabGroup.type_1_bg.pgNavy .tabBar { border: 1px solid var(--pgNavy_200) }
.tabGroup.type_1_bg.pgNavy .tabBar .btn:hover { color: #fff; background-color: var(--pgNavy_300); }
.tabGroup.type_1_bg.pgNavy .tabBar .btn.active { color: #fff; background-color: var(--pgNavy_400); }

.tabGroup.type_1_bg.pgYellow .tabBar { border: 1px solid var(--pgYellow_200) }
.tabGroup.type_1_bg.pgYellow .tabBar .btn:hover { color: #fff; background-color: var(--pgYellow_300); }
.tabGroup.type_1_bg.pgYellow .tabBar .btn.active { color: #fff; background-color: var(--pgYellow_400); }


/*========================================================================
* Tab Type_2 [Transparent]
========================================================================*/
/* type_2 */
.tabGroup[class*='type_2'] .tabBar { gap: 16px; }
.tabGroup[class*='type_2'] .tabBar .btn:is(.active, :hover) { box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.1); }
.tabGroup[class*='type_2'] .tabCont { margin-top: 16px; }

.tabGroup.type_2 .tabBar .btn { border: 1px solid transparent; }

.tabGroup.type_2.gray .tabBar .btn:hover { border: 1px solid var(--gray_300); }
.tabGroup.type_2.gray .tabBar .btn.active { border: 1px solid var(--gray_400); }

.tabGroup.type_2.sfrtBlue .tabBar .btn:hover { color: var(--sfrtBlue_400); border: 1px solid var(--sfrtBlue_300); }
.tabGroup.type_2.sfrtBlue .tabBar .btn.active { color: var(--sfrtBlue_400); border: 1px solid var(--sfrtBlue_400); }

.tabGroup.type_2.pgNavy .tabBar .btn:hover { color: var(--pgNavy_400); border: 1px solid var(--pgNavy_300); }
.tabGroup.type_2.pgNavy .tabBar .btn.active { color: var(--pgNavy_400); border: 1px solid var(--pgNavy_400); }

.tabGroup.type_2.pgYellow .tabBar .btn:hover { color: var(--pgYellow_400); border: 1px solid var(--pgYellow_300); }
.tabGroup.type_2.pgYellow .tabBar .btn.active { color: var(--pgYellow_400); border: 1px solid var(--pgYellow_400); }

/* type_2_bg */
.tabGroup.type_2_bg .tabBar .btn { border: 1px solid var(--gray_200); }

.tabGroup.type_2_bg.gray .tabBar .btn:hover,
.tabGroup.type_2_bg.gray .tabBar .btn.active { color: #fff; background-color: var(--gray_400); border: 1px solid var(--gray_400); }

.tabGroup.type_2_bg.sfrtBlue .tabBar .btn:hover,
.tabGroup.type_2_bg.sfrtBlue .tabBar .btn.active { color: #fff; background-color: var(--sfrtBlue_400); border: 1px solid var(--sfrtBlue_400); }

.tabGroup.type_2_bg.pgNavy .tabBar .btn:hover,
.tabGroup.type_2_bg.pgNavy .tabBar .btn.active { color: #fff; background-color: var(--pgNavy_400); border: 1px solid var(--pgNavy_400); }

.tabGroup.type_2_bg.pgYellow .tabBar .btn:hover,
.tabGroup.type_2_bg.pgYellow .tabBar .btn.active { color: #fff; background-color: var(--pgYellow_400); border: 1px solid var(--pgYellow_400); }


/*========================================================================
* Tab Type_3 [Line]
========================================================================*/
.tabGroup.type_3 .tabBar .btn { background-color: #fff; border-bottom: 2px solid var(--gray_200); }
.tabGroup.type_3 .tabCont { border-top: 0; border-color: var(--sfrtBlue_100); }

.tabGroup.type_3.sfrtBlue .tabBar .btn:hover { border-bottom: 2px solid var(--sfrtBlue_200); color: var(--sfrtBlue_400); }
.tabGroup.type_3.sfrtBlue .tabBar .btn.active { border-bottom: 2px solid var(--sfrtBlue_400); color: var(--sfrtBlue_400); }

.tabGroup.type_3.pgNavy .tabBar .btn:hover { border-bottom: 2px solid var(--pgNavy_200); color: var(--pgNavy_400); }
.tabGroup.type_3.pgNavy .tabBar .btn.active { border-bottom: 2px solid var(--pgNavy_400); color: var(--pgNavy_400); }

.tabGroup.type_3.pgYellow .tabBar .btn:hover { border-bottom: 2px solid var(--pgYellow_200); color: var(--pgYellow_400); }
.tabGroup.type_3.pgYellow .tabBar .btn.active { border-bottom: 2px solid var(--pgYellow_400); color: var(--pgYellow_400); }


/*========================================================================
* Tab Type_4 [Line-bg]
========================================================================*/
.tabGroup.type_4 .tabBar { gap: 16px; border-radius: 0; }
.tabGroup.type_4 .tabBar .btn { background-color: rgba(0, 0, 0, 0.05); }
.tabGroup[class*='type_4'] .tabBar .btn { border: 1px solid var(--gray_100); border-bottom: 0; border-bottom-left-radius: 0; border-bottom-right-radius: 0; }
.tabGroup.type_4 .tabCont { border-radius: 0; border-top: 0; border-color: var(--sfrtBlue_100) }

.tabGroup.type_4.sfrtBlue .tabBar { border-bottom: 3px solid var(--sfrtBlue_400); }
.tabGroup.type_4.sfrtBlue .tabBar .btn:is(:hover, .active) { border: 1px solid var(--sfrtBlue_400); border-bottom: 0; color: #fff; background-color: var(--sfrtBlue_400); }

.tabGroup.type_4.pgNavy .tabBar { border-bottom: 3px solid var(--pgNavy_400); }
.tabGroup.type_4.pgNavy .tabBar .btn:is(:hover, .active) { border: 1px solid var(--pgNavy_400); border-bottom: 0; color: #fff; background-color: var(--pgNavy_400); }


/*========================================================================
* Pagination Styles
========================================================================*/
/* Pagination Common */
.pagination { display: inline-flex; }
.pagination button { transition: 0.2s all ease-in-out }


/* Pagination Size */
.pagination.sm { gap: 4px }
.pagination.sm button { min-width: 25px; line-height: 25px; padding: 0 0.4rem; font-size: 0.8rem; }
.pagination.md { gap: 6px }
.pagination.md button { min-width: 35px; line-height: 35px; padding: 0 0.6rem; font-size: 0.9rem; }
.pagination.lg { gap: 8px }
.pagination.lg button { min-width: 45px; line-height: 45px; padding: 0 0.8rem; font-size: 1rem; }


/* Pagination Type_1 */
.pagination.type_1 button:not(.prev, .next) { border-radius: 4px; }

.pagination.type_1 button.prev + button.active,
.pagination.type_1 button.active + button.next { margin-left: 4px; }
.pagination.type_1 button:not(:disabled, .active):hover { color: var(--sfrtBlue_400); }
.pagination.type_1 button.active { color: #fff; background-color: var(--sfrtBlue_400); }

.pagination.type_1 :disabled { color: var(--gray_300); }


/* Pagination Type_2 */
.pagination.type_2 button { border-radius: 50%; }
.pagination.type_2 button:not(.first, .prev, .next, .last, .active):hover { background-color: rgba(0, 0, 0, 0.04); }
.pagination.type_2 button:disabled { color: rgba(0, 0, 0, 0.3); }

.pagination.type_2 button.active { color: #fff; background-color: rgba(0, 0, 0, 0.8)}
.pagination.type_2 button i { font-size: 1.2rem; }
