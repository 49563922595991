@charset "UTF-8";

#contents .inner { padding: 7rem 2rem; }
#contents .visual.kor .inner { padding: 4rem 2rem; }
.inner { margin: 0 auto; max-width: 1200px; }

@media screen and (max-width: 1024px) {
  #contents .inner { padding: 5rem 2rem; }
  #contents .visual.kor .inner { padding: 3rem 2rem; }
}
@media screen and (max-width: 767px) {
  #contents .inner { padding: 4rem 1rem; }
  #contents .visual.kor .inner { padding: 2.5rem 1rem; }
}
@media screen and (max-width: 550px) {
  #contents .inner { padding: 3rem 1rem; }
  #contents .visual.kor .inner { padding: 2rem 1rem; }
}

/*========================================================================
* Main Visual
========================================================================*/
.visual { background-repeat: no-repeat; background-position: center top; background-size: cover; position: relative; }
.visual .inner { display: flex; justify-content: center; align-items: center; height: 100%; position: relative; z-index: 10; }
.visual .inner .copyTxt { max-width: 980px; font-size: 4rem; font-weight: 600; color: #FFF; text-align: center; line-height: 1.2; text-shadow: 0.25rem 0.25rem rgba(0,0,0,0.1); }

.visual.fix { background-attachment: fixed; }
.visual.main_01 { height: 100vh; background-image: url("../../img/main/visual.jpg"); }
.visual.main_01:before { content: ""; width: 100%; height: 100%; background: linear-gradient(rgba(0,0,0,0.3), transparent); backdrop-filter: blur(2px); position: absolute; top: 0; left: 0; }
.visual.main_02 { height: 750px; background-image: url("../../img/main/visual_02.jpg");}
.visual.main_03 { height: 300px; background-image: url("../../img/main/visual_03.jpg");}

@media screen and (max-width: 1280px) {
  .visual .inner .copyTxt { font-size: 3.5rem; }
  .visual.main_02 { height: 700px; }
}
@media screen and (max-width: 1024px) {
  .visual .inner .copyTxt { font-size: 3rem; }
  .visual.main_02 { height: 600px; }
}
@media screen and (max-width: 767px) {
  .visual .inner .copyTxt { font-size: 2.4rem; }
  .visual.main_02 { height: 500px; }
}
@media screen and (max-width: 550px) {
  .visual .inner .copyTxt { font-size: 1.8rem; }
  .visual.main_02 { height: 450px; }
  .visual.main_03 { height: 250px; }
}
@media screen and (max-width: 360px) {
  .visual .inner .copyTxt { font-size: 1.6rem; }
  .visual.main_02 { height: 400px; }
}

/*========================================================================
* Common Layout
========================================================================*/
span.goldTxt { color: var(--gold); font-weight: 500; }
:is(.infoTxt, .titInfoTxt) { text-align: center; line-height: 1.8; letter-spacing: 1px; color: #666; font-size: 1rem; }
:is(.infoTxt, .titInfoTxt) > * + * { margin-top: 24px; }
.titInfoTxt { padding: 0 2rem; }
.titInfoTxt + * { margin-top: 50px; }
.titInfoTxt.mar { margin-bottom: 75px; }

.inner .container + .container { margin-top: 80px; }
.titWrap { display: flex; justify-content: center; align-items: flex-end; gap: 20px; width: 100%; margin-bottom: 50px; }
.moreBtn { display: flex; justify-content: center; align-items: center; width: 42px; height: 42px; color: #444; border: 1px solid #444; border-radius: 25px; transition: 0.2s ease-in-out; font-size: 1rem; }
.moreBtn i { position: relative; top: 1px; }
.moreBtn:hover { color: #fff; border-color: #333; background-color: #333; }
.contTit { text-align: center; font-size: 3.2rem; font-weight: 300; letter-spacing: 3px;  }

@media screen and (max-width: 1280px) {
  .titWrap { margin-bottom: 35px; }
  .moreBtn { width: 38px; height: 38px; }
  .contTit { font-size: 2.8rem; }
  .titInfoTxt { font-size: 0.9rem; }
}
@media screen and (max-width: 1024px) {
  .titWrap { gap: 10px; }
  .moreBtn { width: 34px; height: 34px; }
  .moreBtn i { font-size: 0.8rem; }
  .contTit { font-size: 2.4rem; }
}
@media screen and (max-width: 767px) {
  .titWrap { margin-bottom: 25px; }
  .moreBtn { width: 26px; height: 26px; }
  .moreBtn i { font-size: 0.7rem; }
  .contTit { font-size: 1.85rem; letter-spacing: 1px; }
  .titInfoTxt { padding: 0.5rem; font-size: 0.85rem; }
}
@media screen and (max-width: 550px) {
  .titWrap { margin-bottom: 20px; align-items: center; }
  .moreBtn { width: 24px; height: 24px; }
  .moreBtn i { font-size: 0.65rem; }
  .contTit { line-height: 1.4; font-size: 1.6rem; letter-spacing: 0px; }
}
@media screen and (max-width: 360px) {
  .titInfoTxt { padding: 0; }
}

/* Sub Common */
.subTit { margin-bottom: 8px; font-size: 1.3rem; line-height: 1.4; }
:is(.subTit, .subTit_02, .subContTit) ~ .infoTxt { text-align: left; }
.subContTit { display: inline-flex; max-width: 0; line-height: 40px;  text-align: center; white-space: nowrap; color: #fff; background-color: #222;  font-size: 1rem; overflow: hidden; }
.subContTit.on  { animation: titleMotion 0.8s alternate forwards; }
@keyframes titleMotion {
  to { padding: 0 2rem; max-width: 1200px; }
}
.subContTit + * { margin-top: 30px; }
.subTit_02 { color: var(--gold); font-size: 1.2rem; }
.subTit_02 + * { margin-top: 15px; }
.subCont .infoTxt { line-height: 1.6; color: #333 }
.subCont.col > .infoTxt:first-child { margin-bottom: 20px; text-align: left; }
.subCont.col img { width: 100%; margin-bottom: 10px; }
.subCont.col > img + *:not(.imgName) { margin-top: 40px; }
.imgName { display: flex; text-align: center; margin-bottom: 40px; }
.imgName span { flex: 1; font-weight: 500; }


.subCont.row { display: flex; gap: 30px; }
.subCont.row + .subCont.row { margin-top: 20px; }
.subCont.benefits img { max-height: 160px; max-width: 200px; }
.contBox + .contBox { padding-top: 60px; }

@media screen and (max-width: 1024px) {
  .subTit { font-size: 1.2rem; }
}
@media screen and (max-width: 767px) {
  .subTit { font-size: 1rem; }
  .subTit_02 { font-size: 1.1rem; }
  .subTit_02 + * { margin-top: 10px; }
  .infoTxt { font-size: 0.9rem; }
  .subCont.row.fido { justify-content: center; flex-wrap: wrap; }
  .subCont .imgNameRow { overflow-x: auto; }
  .subCont .imgNameRow::-webkit-scrollbar { display: none; }
  .subCont .imgNameRow img { max-width: unset; width: auto; }
  .subCont .imgNameRow .imgName { width: 864px; }
}
@media screen and (max-width: 550px) {
  .contBox + .contBox { padding-top: 40px; }
  .subCont.row.benefits { flex-direction: column; gap: 20px; }
  .subCont.row.benefits + .subCont.row.benefits { margin-top: 40px;}
  .subCont.row.benefits :is(img, br) { display: none; }
}
@media screen and (max-width: 360px) {
  .subContTit { font-size: 0.9rem; padding: 0; }
  @keyframes titleMotion {
    to { padding: 0 1rem; max-width: 100vw; }
  }  
}

/*========================================================================
* Common Component
========================================================================*/
/* Input */
.input { width: 100%; height: 40px; border: 1px solid #666; transition: 0.2s ease-in-out; }
.input:focus { border-color: #5993ff }
.input.line { border-top: 0; border-right: 0; border-left: 0; }

.input.round_10 { border-radius: 4px; }
.input.round_100 { border-radius: 20px; }

/* Select Box */
select {
  -webkit-appearance: none;
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 10px) calc(1em + 2px),
    calc(100% - 2.1em) 50%;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.2em;
  background-repeat: no-repeat;
}
.select { min-width: 140px; height: 40px; padding: 0 2.1rem 0 1rem; border: 1px solid #666; color: rgba(0,0,0,0.7); }
.select.line { border-top: 0; border-right: 0; border-left: 0; }

.select.round_10 { border-radius: 4px; }
.select.round_100 { border-radius: 20px; }
