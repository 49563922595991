@charset "UTF-8";

:root {
  --btnBlue_100: #4670B9;
}


/*========================================================================
* Button Common
========================================================================*/
.btn { display: inline-flex; justify-content: center; align-items: center; /*width: 120px;*/ font-weight: 400; white-space: nowrap; transition: 0.2s ease-in-out; }

/*========================================================================
* Icon-Text between padding
========================================================================*/
.btn.i_col { flex-direction: column; justify-content: center; gap: 1px; }
.btn.between { justify-content: space-between; }

/*========================================================================
* Button Round
========================================================================*/
.btn.round_10 { border-radius: 4px; }
.btn.round_100 { border-radius: 40px; }


/*========================================================================
* Button Size [xs, md, lg, xl]
========================================================================*/
/* .btn.xxs { gap: 6px; height: 20px; padding: 0 1rem; font-size: 0.65rem; } */
.btn.xs { gap: 6px; height: 30px; padding: 0 1.2rem; font-size: 0.75rem; }
.btn.sm { gap: 6px; height: 35px; padding: 0 1.4rem; font-size: 0.85rem; }
/* .btn.sm i { font-size: 0.75rem; } */
.btn.md { gap: 8px; height: 40px; padding: 0 1.6rem; font-size: 0.95rem; }
.btn.lg { gap: 8px; height: 45px; padding: 0 1.8rem; font-size: 1.05rem; }
.btn.xl { gap: 8px; height: 50px; padding: 0 2rem; font-size: 1.15rem; }
/* .btn.xxl { min-width: 180px; height: 100px; padding: 0 1.8rem; font-size: 1.2rem; } */

/* Icon Vertical Arrange */
.btn.i_col.xs { gap: 2px; height: 35px; font-size: 0.65rem }
.btn.i_col.sm { gap: 2px; height: 40px; font-size: 0.75rem }
.btn.i_col.md { gap: 2px; height: 45px; font-size: 0.85rem }
.btn.i_col.lg { gap: 2px; height: 50px; font-size: 0.95rem }
.btn.i_col.xl { gap: 2px; height: 55px; font-size: 1.05rem }

.btn.i_only.xl i { font-size: 1.5rem }
.btn.i_only.lg i { font-size: 1.4rem }
.btn.i_only.md i { font-size: 1.3rem }
.btn.i_only.sm i { font-size: 1.2rem }
.btn.i_only.xs i { font-size: 1.1rem }


/*========================================================================
* Button Text Sort [left, right]
========================================================================*/
.btn.lt { justify-content: flex-start; }
.btn.rt { justify-content: flex-end; }


/*========================================================================
* Button Colors
========================================================================*/
/* pgNavy */
.btn.pgNavy_100 { color: #fff; background-color: var(--pgNavy_100); }
.btn.pgNavy_200 { color: #fff; background-color: var(--pgNavy_200); }
.btn.pgNavy_300 { color: #fff; background-color: var(--pgNavy_300); }
.btn.pgNavy_400 { color: #fff; background-color: var(--pgNavy_400); }
.btn.pgNavy_edge { color: var(--pgNavy_400); border: 1px solid var(--gray_300); }
.btn.pgNavy_edge:hover { border-color: var(--gray_400); }

/* pgYellow */
.btn.pgYellow_100 { color: #fff; background-color: var(--pgYellow_100); }
.btn.pgYellow_200 { color: #fff; background-color: var(--pgYellow_200); }
.btn.pgYellow_300 { color: #fff; background-color: var(--pgYellow_300); }
.btn.pgYellow_400 { color: #fff; background-color: var(--pgYellow_400); }
.btn.pgYellow_edge { color: var(--pgYellow_400); border: 1px solid var(--gray_300); }
.btn.pgYellow_edge:hover { border-color: var(--gray_400); }

.btn.neoBlue { color: #fff; background-color: var(--primary); border: 1px solid var(--primary); }
.btn.neoBlue:hover,
.btn.neoBlue:active { color: #fff; background-color: var(--primary_200); border-color: var(--primary_200); }

/* sfrtBlue */
.btn.sfrtBlue { color: #fff; background-color: var(--sfrtBlue_400); border: 1px solid var(--sfrtBlue_400); }
.btn.sfrtBlue:hover,
.btn.sfrtBlue:active { color: #fff; background-color: var(--sfrtBlue_300); border-color: var(--sfrtBlue_300); }
.btn.blue_edge { color: var(--sfrtBlue_400); border: 1px solid var(--gray_300); }
.btn.blue_edge:hover { border-color: var(--gray_400); }

/* Black */
.btn.black { color: #fff; background-color: var(--pgBlack); }
.btn.black:hover { background-color: var(--pgBlack); }
.btn.black_edge { border: 1px solid var(--gray_300); color: var(--pgBlack); }
.btn.black_edge:hover { border-color: var(--pgBlack); }

/* Gray */
.btn.gray_100 { color: var(--gray_400); background-color: var(--gray_100); }
.btn.gray_100:hover { background-color: var(--gray_200); }
.btn.gray_200 { color: #fff; background-color: var(--gray_200); }
.btn.gray_200:hover { background-color: var(--gray_300); }
.btn.gray_300 { color: #fff; background-color: var(--gray_300); }
.btn.gray_300:hover { background-color: var(--gray_400); }
.btn.gray_400 { color: #fff; background-color: var(--gray_400); }
.btn.gray_400:hover { background-color: var(--gray_500); }

.btn.green { color: #fff; background-color: #39af8a; }
.btn.green:hover { color: #fff; background-color: #68b99f; }
.btn.red { color: #fff; background-color: #ff3c45; }
.btn.red:hover { color: #fff; background-color: #ff666e; }

/* Transparent */
.btn.transparent { color: var(--gray_700); background-color: transparent; }

