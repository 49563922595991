@charset "UTF-8";

/* 
  Basic input, Textarea, File type input
*/

/*========================================================================
* Input Common
========================================================================*/
.input { padding: 0 1rem; height: 35px; font-size: 0.9rem; transition: 0.2s ease-in-out; color: var(--gray_400); border: 1px solid rgba(0, 0, 0, 0.3); }
.input .full { width: 100%; }
.input + * > i { margin-right: 4px; }
.input:disabled { background-color: #f1f1f1 }
.input:is(:hover, .hover):not(:read-only, :disabled, .error, .success) { border-color: #777; }
.input:is(:focus, .focus):not(:read-only, :disabled, .error, .success) { border-color: var(--sfrtBlue_400); box-shadow: 0 5px 10px rgba(0, 0, 0, 0.075); }
.input:is(:disabled, :read-only)::placeholder { color: rgba(0,0,0,0.5)}

.input.line.white { background-color: transparent; }
.input.line.i_rt { padding-left: 0; }
.input.line:disabled { background-color: #fff; opacity: 0.7; }

.input:is(.line_bg, .line_none) { background-color: rgba(0,0,0,0.04); }
.input:is(.line_bg, .line_none).white { background-color: #fff; }
.input:is(.line_bg, .line_none):read-only { opacity: 0.7; }
.input:is(.line_bg, .line_none):disabled { background-color: var(--gray_100); }

.input.line_none { border-bottom: 0; }
.input.line_none.error { border: 1px solid var(--errorColor); }
.input.line_none.success { border: 1px solid var(--successColor); }

.input:is(.line, .line_bg, .line_none) { border-top: 0; border-right: 0; border-left: 0; }
.input:is(:focus, .focus).line { box-shadow: unset; }

.input.error { border-color: var(--errorColor); }
.input.error + i { color: var(--errorColor); }
.input.success { border-color: var(--successColor); }
.input.success + i { color: var(--successColor); }


/*========================================================================
* Input Size [sm, md, lg]
========================================================================*/
.input.sm { padding: 0 0.75rem; height: 30px; font-size: 0.75rem; }
.input.sm::placeholder { font-size: 0.73rem; }
.input.md { padding: 0 0.75rem; height: 35px; font-size: 0.825rem; }
.input.md::placeholder { font-size: 0.805rem; }
.input.lg { padding: 0 0.75rem; height: 40px; font-size: 0.9rem; }
.input.lg::placeholder { font-size: 0.88rem; }

/*========================================================================
* Input Round Settings
========================================================================*/
.input.round_10 { border-radius: 4px; }
.input.round_100 { border-radius: 40px; }


/*========================================================================
* Input with Icon
========================================================================*/
/* Icon Size */
.inputIco { position: relative; width: 100%; }
.inputIco > .input { width: 100%; } 
/* .inputIco > i { font-size: 1rem; } */
.inputIco + * > i { margin-right: 4px; }

/* Input Padding */
.inputIco .i_lt { padding: 0 0.75rem 0 2.35rem; }
.inputIco .i_rt { padding: 0 2.35rem 0 0.75rem; }

/* Icon Position */
.inputIco .i_lt + i { position: absolute; top: 50%; left: 15px; transform: translate(-25%, -50%); }
.inputIco .i_rt + i { position: absolute; top: 50%; right: 15px; transform: translate(25%, -50%); }


/*========================================================================
* Text Area Styles
========================================================================*/
textarea { resize: none; }
.textarea { padding: 0.6rem 0.75rem; width: 225px; height: 100px; font-size: 0.9rem; transition: 0.2s ease-in-out; color: var(--gray_400); border: 1px solid rgba(0, 0, 0, 0.3); overflow-y: auto }
.textarea.full { width: 100%; }
.textarea:is(:disabled, :read-only)::placeholder { font-size: 0.8rem; color: rgba(0,0,0,0.5); }

.textarea.round { border-radius: 4px; }
/* .textarea.line { border: none; border-bottom: 1px solid var(--gray_300); border-radius: 0; } */

.textarea:read-only { opacity: 0.7; }
.textarea:disabled { background-color: #F1F1F1; }
.textarea:not(:read-only, .success, .error):focus { border-color: var(--sfrtBlue_400); box-shadow: 0 5px 10px rgba(0, 0, 0, 0.075); }
.textarea:not(:read-only, .success, .error):hover { border-color: #777; }

.textarea.error { border-color: var(--errorColor); }
.textarea.success { border-color: var(--successColor); }


/*========================================================================
* Input type="file" Styles
========================================================================*/
input.file { display: none; }

label.file { width: 450px; }
label.file.type_1 { display: flex; justify-content: space-between; align-items: center; color: rgba(0, 0, 0, 0.4); cursor: pointer; }
label.file.type_1.active{ color: var(--gray_400) }
label.file.type_1 i { font-size: 1rem; transition: 0.3s ease-in-out; }
label.file.type_1:hover { border-color: var(--gray_400); }
label.file.type_1:hover i { color: var(--gray_400); }

label.file.type_2 { display: flex; flex-direction: column; justify-content: center; align-items: center; height: auto; padding: 2rem; background-color: #f9f9fb; border: 1px dashed rgba(0, 0, 0, 0.3); }
label.file.type_2 i { position: relative; margin-bottom: 12px; font-size: 2.6rem; transition: 0.3s ease-in-out; }
label.file.type_2:hover { border-color: #777; }